<script setup lang="ts">
import { subject } from '@casl/ability';
import { serviceToSteps } from '@respell/steps';
import CreateMemberModal from '~/components/modals/CreateMemberModal.vue';
import EmptyState from '~/components/table/EmptyState.vue';
import { useAppAbility } from '~/composables/useAppAbility';
import type { Spell } from '~/stores/spells';
import { useSpellsStore } from '~/stores/spells';
import { useWorkspaceStore } from '~/stores/workspaces';
import type { Filter } from '~/util/filters';
import { spellFilters } from '~/util/filters';

const ability = useAppAbility();
const { can } = ability;

const workspaceStore = useWorkspaceStore();
const spellStore = useSpellsStore();

const modal = useModal();

const { team, teamMembers } = storeToRefs(workspaceStore);
const { teamSpells } = storeToRefs(spellStore);
const teamId = useRouteParams('teamId');

const dateRange = ref<{ start: Date; end: Date } | undefined>();

const liveQuery = ref('');
const searchQuery = ref('');

const clear = () => {
  liveQuery.value = '';
  searchQuery.value = '';
};

const debouncedSearch = useDebounceFn(
  (query: string) => {
    searchQuery.value = query;
  },
  300,
  { maxWait: 500 },
);

const filters = ref({
  type: [] as string[],
  integration: [] as string[],
  q: searchQuery,
  dateRange,
}) as Record<string, any>;

const filterLabel = (filter: Filter) => {
  const selectedFilters = filters.value[filter.key];
  switch (selectedFilters.length) {
    case 0:
      return filter.name;
    case 1:
      return selectedFilters[0]?.name;
    default:
      return `${filter.name}: (${selectedFilters.length})`;
  }
};

const { error: teamError } = await useAsyncData(
  () => workspaceStore.loadTeam(teamId.value as string),
  {
    watch: [teamId],
    immediate: !!teamId.value,
  },
);

const { pending: isFetching } = await useAsyncCache(
  `teamSpells/${teamId.value}`,
  () => spellStore.loadTeamSpells(teamId.value as string),
  {
    watch: [teamId],
    immediate: !!teamId.value,
    lazy: true,
  },
);

const filteredSpells = computed(() => {
  let spells: Spell[] = teamSpells.value;
  const { type, integration, dateRange, q } = filters.value;

  // Type filter
  const typeKeys = type.map((filter: Filter) => filter.key);

  if (type && type.length > 0) {
    spells = spells.filter((spell: Spell) => {
      const liveVersion = spell.versions.find(
        (version) => version.type === 'live',
      );

      const isManualOnly =
        typeKeys.includes('manual') && !typeKeys.includes('trigger');
      const isTriggerOnly =
        typeKeys.includes('trigger') && !typeKeys.includes('manual');

      if (isManualOnly) return !liveVersion?.trigger;
      if (isTriggerOnly) return !!liveVersion?.trigger;

      return true;
    });
  }

  // Integrations filter
  const integrationKeys = integration.map((filter: Filter) => filter.key);

  if (integration && integration.length > 0) {
    spells = spells.filter((spell) => {
      const liveVersion = spell.versions.find(
        (version) => version.type === 'live',
      );
      return liveVersion?.steps.some((step) =>
        integrationKeys.some((key: string) =>
          (serviceToSteps[key] || []).includes(step.key),
        ),
      );
    });
  }

  // Date filter
  if (dateRange) {
    spells = spells.filter((spell) => {
      const spellCreatedAt = new Date(spell.createdAt);
      return (
        spellCreatedAt >= dateRange.start && spellCreatedAt <= dateRange.end
      );
    });
  }

  // Search query filter
  if (q) {
    const searchQuery = q.toLowerCase();
    spells = spells.filter(
      (spell) =>
        spell.name.toLowerCase().includes(searchQuery) ||
        spell.description.toLowerCase().includes(searchQuery),
    );
  }

  return spells;
});

watchImmediate(teamError, () => {
  if (teamError.value) {
    throw new Error(teamError.value.message);
  }
});

const spellCount = computed(() => filteredSpells.value?.length);

const createSpellInTeam = () => {
  spellStore.createSpell({ teamId: team.value?.id as string });
};

const openCreateMemberModal = () => {
  modal.open(CreateMemberModal);
};
</script>

<template>
  <NuxtLayout name="dashboard">
    <div class="flex justify-between items-center">
      <p class="main-title">
        {{ team?.name }}
      </p>
      <UButton size="xl" @click="createSpellInTeam"> New Spell</UButton>
    </div>
    <UAvatarGroup size="sm" :max="2" class="mt-8">
      <div v-for="member in teamMembers" :key="member.id">
        <UTooltip :text="member.user?.profile?.fullName || ''">
          <UAvatar :alt="member.user?.profile?.fullName || ''" size="sm" />
        </UTooltip>
      </div>
    </UAvatarGroup>
    <UTooltip
      v-if="can('addMember', subject('Team', team))"
      text="Add members"
      :popper="{ placement: 'right' }"
    >
      <UButton
        icon="i-ph-user-plus"
        class="text-gray-400 mb-[-24px]"
        variant="ghost"
        @click="openCreateMemberModal"
      />
    </UTooltip>

    <!--    MEMBER AND SPELL COUNT-->
    <div class="flex justify-between">
      <span v-if="teamMembers?.length > 1" class="dimmed text-sm">
        {{ teamMembers?.length }} members
      </span>
      <span v-else> </span>

      <span class="dimmed text-sm" :class="spellCount === 0 && 'invisible'">
        {{ spellCount }} spells
      </span>
    </div>

    <!--    FILTERS-->
    <div class="py-6 pb-6 top-0 left-0 flex flex-row space-x-3 justify-start">
      <div v-for="filter in spellFilters" :key="filter.key">
        <USelectMenu
          v-model="filters[filter.key]"
          :options="filter.options"
          :popper="{ placement: 'bottom' }"
          multiple
        >
          <UButton
            size="xl"
            truncate
            class="flex-1"
            :color="filters[filter.key].length ? 'primary' : 'gray'"
            :ui="{
              rounded: 'rounded-full',
              base: filters[filter.key].length > 0 ? 'shrink-0' : 'shrink',
            }"
            :variant="filters[filter.key].length > 0 ? 'solid' : 'outline'"
          >
            <UIcon
              v-if="filters[filter.key].length === 1"
              :name="filters[filter.key][0].icon"
              class="text-2xl"
            />
            <span class="truncate">
              {{ filterLabel(filter) }}
            </span>
            <UIcon
              :name="
                filters[filter.key].length > 0
                  ? 'i-ph-caret-down-bold'
                  : 'i-ph-caret-down'
              "
            />
          </UButton>

          <template #option="{ option: category }">
            <span class="flex gap-1">
              <UIcon :name="category.icon" />
              <span class="truncate">{{ category.name }}</span>
            </span>
          </template>
        </USelectMenu>
      </div>

      <!--      DATE RANGE-->
      <AppCalendar v-model="dateRange" type="daterange" class="shrink-0" />

      <!--      SEARCH-->
      <div class="w-full">
        <UInput
          v-model="liveQuery"
          icon="i-ph-magnifying-glass-bold"
          color="white"
          variant="outline"
          class="grow"
          size="lg"
          placeholder="Search your spells..."
          :ui="{ icon: { trailing: { pointer: '' } } }"
          @input="debouncedSearch($event.target.value)"
        >
          <template #trailing>
            <UButton
              v-show="liveQuery !== ''"
              color="gray"
              variant="link"
              icon="i-ph-x"
              :padded="false"
              @click="clear"
            />
          </template>
        </UInput>
      </div>
    </div>

    <hr class="mt-8 mb-8" />

    <!--    SPELL CARD LISTING-->
    <div v-if="spellCount > 0" class="spell-grid">
      <AppSpellCard
        v-for="spell in filteredSpells"
        :key="spell.id"
        :spell="spell"
      />
    </div>
    <div v-else-if="isFetching" class="spell-grid">
      <USkeleton v-for="i in 15" :key="i" class="h-56 w-[22rem] rounded-xl" />
    </div>
    <div v-else>
      <empty-state icon-name="i-ph-magic-wand">
        <p class="text-lg font-bold">No spells yet</p>
        <p class="dimmed">
          Create your first spell from scratch or start with our templates
        </p>
        <div class="flex py-s gap-4">
          <UButton
            icon="i-ph-stack"
            type="button"
            size="xl"
            color="white"
            @click="navigateTo('/templates')"
          >
            See Templates
          </UButton>
          <UButton
            icon="i-ph-plus"
            type="button"
            size="xl"
            @click="createSpellInTeam"
          >
            New Spell
          </UButton>
        </div>
      </empty-state>
    </div>
  </NuxtLayout>
</template>
